import React from 'react'
import Layout from '../components/layout'
import Office from '../components/office'
import Gap from '../components/gap'
import SEO from '../components/seo'

const OfficePage = () => {
    return (
        <Layout currentPage="office">
            <SEO
                title='Office'
            />
            <Office/>
            <Gap height={120}/>
        </Layout>
    )
}

export default OfficePage
