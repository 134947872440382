import React, { useEffect, useState } from 'react'
import {officeWrapper,officeThumbnail,officeContent,imgThumbnail,contentDate,contentTitle,contentPreview,show} from './office.module.css'
import Gap from './gap'
import { StaticImage } from 'gatsby-plugin-image'

const Office = () => {

    const [isShowOffice,setIsShowOffice] = useState(false);

    useEffect(()=>{
        setTimeout(()=>{
            setIsShowOffice(true)
        },500)
    },[]);

    return (
        <a href="https://destinasicomputindo.com" target="__blank" className={(isShowOffice)?`${officeWrapper} ${show}` : `${officeWrapper}`}>
            <div className={officeThumbnail}>
                <StaticImage className={imgThumbnail} src='../images/office.jpg' alt='office-thumbnail'/>
            </div>
            <div className={officeContent}>
                <p className={contentDate}>Sejak : 2018 - Sekarang</p>
                <Gap height={30}/>
                <h3 className={contentTitle}>CV Destinasi Computindo</h3>
                <Gap height={20}/>
                <p className={contentPreview}>Perusahaan yang buat aku belajar banyak tentang dunia IT khususon dipemrograman atau pengcodingan. Dimulai dari pembuatan UI/UX (Figma / Adobe XD), Database Management (Oracle / Sql Server / MongoDB), REST API (Slim PHP / Express JS),frontend (Pure HTML,CSS,Javascript / React JS ), backend (PHP / Node JS), mobile (Flutter) dan lain sebagainya. Aku jadi paham betul flow pembuatan sebuah aplikasi dari 0 hingga jadi aplikasi utuh berkat project yang diberikan oleh perusahaan ini (sangat membantu sekali). Thanks to this company. </p>
            </div>
        </a>
    )
}

export default Office
